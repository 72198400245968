import React, { useState } from "react"
import pageStyles from "../components/page.module.css"
import Page from "../components/page"
import styles from "./contact.module.css"

export default function Contact({ location }) {
  const [thanks, setThanks] = useState(false)
  const [sorry, setSorry] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [errors, setErrors] = useState({ name: "", email: "", message: "" })
  const [submitVisible, setSubmitVisible] = useState(true)


  const sendForm = () => {
    let hadErrors = false

    setSubmitVisible(false)
    setErrors({ name: "", email: "", message: "" })

    let errs = {...errors};

    if (!name.length) {
      errs.name = "Field required";
      hadErrors = true
    }

    if (!email.length) {
      errs.email = "Field required";
      hadErrors = true
    }

    if (!message.length) {
      errs.message = "Field required";
      hadErrors = true
    }

    if (hadErrors) {
      setErrors(errs);
      setSubmitVisible(true);
      return;
    }

    fetch("https://shop.phototailors.com/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: name,
        email: email,
        message: message
      })
    }).then(function(response) {
      if(response.status !== 200){
        setSorry(true)
        return null;
      }
      return response.json()
    }).then(function(data) {
      setSubmitVisible(false);

      if(data.errors){
        setErrors(data.errors);
        setSubmitVisible(true);
      } else {
        setThanks(true);
      }

      setName('');
      setMessage('');
      setEmail('');
    }).catch(function(ex) {
      setSubmitVisible(false);
    })
  }

  return (
    <Page title={"Contact us - Phototailors"} location={location}>
      <div className={pageStyles.sectionWrapper}>
        <div className={pageStyles.section}>
          <div className={styles.contact}>

            <div className={styles.left}>
              <h1>GOT ANY <br/>
                QUESTIONS?</h1>
              <br/>
              <br/>
              <div>contact@phototailors.com</div>
            </div>

            <div className={styles.right}>
              <div className={styles.form}>
                <form action='https://shop.phototailors.com/api/contact' method='POST'>
                  <div className={styles.upperForm}>

                    <div className={styles.inputElement}>
                      <div className={styles.error}>{errors.name}</div>
                      <input type="text" name="your-name" size="40"
                             className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true"
                             aria-invalid="false" placeholder="NAME" value={name}
                             onChange={(e) => setName(e.target.value)}/>
                    </div>

                    <div className={styles.inputElement}>
                      <div className={styles.error}>{errors.email}</div>
                      <input type="email" name="your-email" size="40"
                             aria-required="true" aria-invalid="false" placeholder="EMAIL" value={email}
                             onChange={(e) => setEmail(e.target.value)}/>
                    </div>

                  </div>
                  <div className={styles.lowerForm}>
                    <div className={styles.inputElement}>
                      <div className={styles.error}>{errors.message}</div>
                      <textarea name="your-message" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea"
                                aria-invalid="false" placeholder="MESSAGE" value={message}
                                onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                  </div>
                </form>
                <div className={styles.submitButtonWrapper}>
                  {submitVisible &&
                  <button className={styles.submitButton} onClick={sendForm}>SEND</button>
                  }
                  {
                    thanks &&
                    <div className={styles.submittedButton}>Thank you. We'll get back to you really soon.</div>
                  }
                  {
                    sorry &&
                    <div className={styles.submittedErrorButton}>We are sorry. There was an error submiting your message. Please contact us via email directly.</div>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Page>
  )
}